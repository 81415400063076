<template>
  <div class="container ds-home">
    <h1 style="color:#fff;">页面装修中...</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.ds-home{
  display: flex;
  // width: 100%;
  min-height: 1000px;
}
</style>
